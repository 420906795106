<template>  
  <v-container style="max-width: 600px;">  
    <v-overlay v-model="showSponsor" class="justify-center align-center">
      <v-card color="primary">  
        <v-card-title class="primary">Brought to you by...</v-card-title>
        <a href="https://www.megastarfinancial.com/" target="_blank">
          <v-img  
            src="MegaStar.png"  
            max-width="375"  
            class="image-with-border image-with-rounded-corners"
          ></v-img>
        </a>  
      </v-card>  
    </v-overlay>
    <v-overlay v-model="showProgress" class="justify-center align-center">
      <v-progress-circular
        indeterminate
        size="60"
        width="5"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div ref="spacer" v-show="!showPassage">
      <v-container class="fill-height d-flex align-center justify-center">  
          <v-layout align-center justify-center>  
            <v-flex>  
              <h4 :style="{ color: isLocked ? 'red' : 'black'}" class="text-center">{{ insteadOfPassage }}</h4>  
            </v-flex>  
          </v-layout>  
      </v-container>  
    </div> 
    <div v-show="showPassage">
      <v-card  class="mb-n6">  
        <v-card-text></v-card-text>
        <!-- <v-card-text>   -->
          <div >
            <p v-if="!firstTime" class="mx-4 font-weight-bold">Welcome to tutored brAIn!</p>  
          </div>
          <div ref="passage">
            <p class="mx-4 font-weight-regular">{{ passage }}</p>  
          </div>
          <!-- <v-card-text v-if="!showBtn"></v-card-text> -->
        <!-- </v-card-text>   -->
        <!-- <v-card-actions>   -->
          <v-row v-if="showBtn" no-gutters class="mt-6">
            <v-col v-if="numOfQ === 2" cols="5" class="ml-4">
              <v-select
                :items="itemLevels"
                item-text="ageGrp"
                item-value="ageGrpID"
                label="Age Group"
                outlined
                v-model="level"
                return-object
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-btn v-if="level.ageGrpID" rounded class="ml-4 mb-6" elevation="25"  color="pink accent-3 white--text" @click="genQ">{{ btnLabel }}</v-btn>  
            </v-col>
          </v-row>
          <v-card-text v-if="!showBtn"></v-card-text>
        <!-- </v-card-actions>   -->
      </v-card>  
      <v-card v-if="question">  
        <v-card-text>  
          <p>{{ question }}</p>  
        </v-card-text>  
      </v-card>  
    </div>
    <v-img class="mt-10" v-if="!firstTime" src="tutoredbrainmainpage.png"></v-img>
    <!-- AI Conversation  -->
    <v-row class="mt-5">
        <v-col>
          <v-responsive v-if="activeChat" height="auto">
            <v-card flat>
              <v-card-text class="flex-grow-1 overflow-y-auto">
                <template v-for="(msg) in messagesAll">
                  <div :class="{ 'd-flex flex-row-reverse': msg.me }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }">
                          <v-chip
                            :color="msg.me ? 'deep-purple accent-4' : msg.tip ? 'blue accent-4' : 'blue darken-4'"
                            dark
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-2">
                              {{ msg.content }}
                          </v-chip>
                        </v-hover>
                      </template>
                    </v-menu>
                  </div>
                </template>
              </v-card-text>
              <v-card-text class="flex-shrink-1 mb-8" v-if="showResponseSection">
                  <v-text-field
                  v-model="messageForm.content"
                  label="Enter answer here..."
                  type="text"
                  no-details
                  variant="outlined"
                  :append-outer-icon="messageForm.content ? 'mdi-check-circle' : null"
                  @keyup.enter="messageForm.content && checkOnAnswer"
                  @click:append-outer="checkOnAnswer"
                  hide-details
                />
              </v-card-text>
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
      <!-- Progress Report -->
      <v-row justify="center" no-gutters>
        <v-col cols="auto">
          <h6 v-if="totalQuestionsEasy > 0">Passage Shown</h6>

          <v-row justify="center" no-gutters>
            <v-progress-circular
              v-if="percentScoreEasy > 0"
              :rotate="360"
              :size="55"
              :width="5"
              :value="percentScoreEasy"
              color="teal"
              class="my-1"
            >
              {{ percentScoreEasy }}%
            </v-progress-circular>
          </v-row>

          <h6 v-if="totalQuestionsEasy > 0" >No. Questions: {{ totalQuestionsEasy }}</h6>

        </v-col>
        <v-col cols="auto" class="ml-8">
          <h6 v-if="totalQuestionsHard > 0">Passage Hidden</h6>

          <v-row justify="center" no-gutters>
            <v-progress-circular
              v-if="percentScoreHard > 0"
              :rotate="360"
              :size="55"
              :width="5"
              :value="percentScoreHard"
              color="teal"
              class="my-1"
            >
              {{ percentScoreHard }}%
            </v-progress-circular>
          </v-row>

        <h6 v-if="totalQuestionsHard > 0" >No. Questions: {{ totalQuestionsHard }}</h6>

        </v-col>
        <v-col cols="auto" class="ml-7">
          <v-switch
            v-if="showSwitch"
            v-model="switchValue"
            label="Show Passage"
            color="primary"
            hide-details
          ></v-switch>
        </v-col>
      </v-row>
      <ErrorPage v-if="showErrorPage" @OK="showErrorPage = false" />
      <Confirm
        v-if="showConfirm"
        :confirmItem="reportItem"
        :confirmMsg="reportMsg"
        @OK="okay"
        @cancel="showConfirm = false"/>
      <!-- <v-row justify="center" no-gutters class="mt-4">  
        <v-btn color="pink accent-3 white--text" v-if="showReport" @click="report">Report an Issue</v-btn>
      </v-row> -->
      <v-card max-width="400" class="mx-auto mt-16" v-if="!firstTime">
        <v-system-bar color="blue darken-3" dark> </v-system-bar>
        <v-app-bar dark color="blue darken-2">
          <v-toolbar-title class="mx-auto">Our Mission</v-toolbar-title>
        </v-app-bar>
        <v-container class="mb-8">
          <v-row dense>
            <v-col cols="12">
              <v-card class="blue darken-3" dark>
                <v-card-subtitle>
                  Our mission at tutoredbrAIn.com is to empower individuals to improve their reading comprehension skills 
                  through the use of innovative technology. Our web app utilizes AI-generated short stories and questions 
                  to help readers enhance their ability to understand and retain information. We believe that everyone should 
                  have access to high-quality reading material and the tools to develop their reading comprehension skills. 
                  Our goal is to create a community of lifelong learners who can confidently engage with various types of text 
                  and gain a deeper understanding of the world around them. At tutoredbrAIn.com, we are committed to promoting literacy 
                  and helping individuals achieve their full potential.
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>      
  </v-container>  
</template>  
  
<script>  
import axios from 'axios';
import ErrorPage from "@/components/ErrorPage.vue"
import Confirm from "@/components/Confirm.vue"

export default {  
  components: {
    ErrorPage,
    Confirm
  },
  data() {  
    return {  
      chatURL: process.env.VUE_APP_CHAT_URL,
      chatApiKey: process.env.VUE_APP_CHAT_APIKEY,

      passage: "Prepare for a journey through imaginative and engaging AI-generated passages. When you are ready, simply choose the appropriate age group, and let AI craft a unique passage for your reading comprehension adventure.",
      question: null,

      showSwitch: false,
      switchValue: true,
      showPassage: true,
      showErrorPage: false,
      showResponseSection: false,
      showProgress: false,
      showSponsor: false,
      showBtn: true,
      activeChat: 1,
      messagesAll: [],
      messageForm: {
        content: "",
        me: true
      },
      reqBody: {
        messages: [],
        temperature: 0.05,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      reqBodyRephrase: {
        messages: [],
        temperature: 0.05,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      reqBodyPassage: {
        messages: [],
        temperature: 0.99,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      numOfQ: 2, // used to count how many questions have been asked per passage
      rightAnswersEasy: 0,
      totalQuestionsEasy: 0,
      percentScoreEasy: 0,
      rightAnswersHard: 0,
      totalQuestionsHard: 0,
      percentScoreHard: 0,

      storageName: "HardMode",
      screenRefresh: true,
      firstTime: null,
      // itemLevels: ["Pre-K", "Kindergarten", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"],
      itemLevels: [
        { ageGrpID: '5to7', ageGrp: '5 to 7 yrs old'},
        { ageGrpID: '8to10', ageGrp: '8 to 10 yrs old'},
        { ageGrpID: '11to13', ageGrp: '11 to 13 yrs old'},
        { ageGrpID: '14to16', ageGrp: '14 to 16 yrs old'},
        { ageGrpID: '17to22', ageGrp: '17 to 22 yrs old'},
      ],
      level: { ageGrpID: null, ageGrp: null},
      passageOffSetHeight: 0,
      failedConsecutiveCount: 0,
      insteadOfPassage: "The passage is hidden to help evaluate your comprehension. However, if you need to see the passage, slide the switch below to 'Show Passage'.",
      showConfirm: false,
      reportItem: [],
      reportMsg: "",
      showReport: false,

      topic: "Lily in an outdoor adventure",
      isLocked: false,

      genre: ['science fiction','romance','drama','western or eastern','autobiography','biography','revenge','war and peace','faith','memoir']
    };  
  },
  computed: {
    btnLabel(){
      if (this.numOfQ === 0){
        return "Generate Question"
      } else if (this.numOfQ === 1){
        return "Generate Another Question"
      } else if (this.numOfQ === 2){
        return "New Passage"
      }
    }, 
    IsDivisible(){
      return (this.totalQuestionsHard + this.totalQuestionsEasy) % 6 === 0;
    }
  },
  created(){
    this.showProgress = true
    this.restoreMode()
  },
  mounted(){
    const spacer = this.$refs.spacer;  
    spacer.style.height = this.passageOffSetHeight; 
  },
  methods: {   
    startTimerForSponsor() {  
      if (this.IsDivisible){
        this.showProgress = false
        this.showSponsor = true 
        setTimeout(() => {  
          this.showSponsor = false;  
        }, 3000);  
      }
    },  
    async genPassage(){
      this.startTimerForSponsor()
      this.reqBody.messages = []
      this.reqBodyRephrase.messages = []
      this.reqBodyPassage.messages = []

      const randomInt = Math.floor(Math.random() * 10)
      const chosenGenre = this.genre[randomInt]

      this.showReport = false
      if(this.failedConsecutiveCount > 7){
        this.showBtn = false
        this.isLocked = true
        this.insteadOfPassage = "Just in case you are a bot, you have been locked out for too many incorrect answers. Please send a message to (469) 525-0450."
        this.showPassage = false
        return;
      }
      this.showProgress = true
 
      this.reqBodyPassage.messages.push(
        {
          role: "system",
          content: "You are a very creative author for " + this.level.ageGrp + " students."
        },      
        {
          role: "user",
          content: "Using a " + chosenGenre + " genre, create a short story with less than 7 sentences for " + this.level.ageGrp + " students that is not about " + this.topic + ". Don't show title for short story."
        }
      )
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBodyPassage),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        this.passage = res.data.choices[0].message.content

        this.numOfQ = 0
        this.messagesAll = []
        this.messageForm.content = ""

        this.showProgress = false
        this.firstTime = "No"

        const passage = this.$refs.passage;  
        this.passageOffSetHeight = (passage.offsetHeight + 100) + "px"
        this.save2local()

      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        this.showProgress=false
        console.log(error);
      }
      // console.log("Gen Passage", this.reqBodyPassage.messages)
    },
    genQ(){
      // console.log(this.showSponsor)
      // console.log(this.totalQuestionsEasy)
      // console.log(this.totalQuestionsHard)
      this.reportItem = []
      this.showReport = false
      if(this.numOfQ === 0){
        this.messagesAll = []
        this.showBtn = false
        this.showSwitch = true
        this.showPassage = false
        this.switchValue = false
        this.gen1stQ()
      } else if (this.numOfQ === 1){
        this.messagesAll = []
        this.showBtn = false
        this.showSwitch = true
        this.showPassage = false
        this.switchValue = false
        this.whatIsTopic()
      } else if (this.numOfQ === 2){
        this.genPassage() //temporary for now
      }
    },
    async gen1stQ(){
      this.showProgress = true
      this.showResponseSection = true
 
      this.reqBody.messages.push(
        {
          role: "system",
          content: "You are an english reading teacher helping me with reading comprehension"
          // content: "You are an english reading teacher helping me with reading comprehension. If my answer is incomplete or wrong, do not ask anymore follow-up questions."
        },      
        {
          role: "user",
          content: "Create a question for the following passage to test whether I understood the passage... " + this.passage
          // content: "Create a question for the following passage to test whether I understood the passage... " + this.passage + " If my answer to your question is incomplete or wrong, do not ask another question and do not ask me to try again."
        }
      )

      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const botMsg = res.data.choices[0].message.content
        this.messagesAll.push({content: botMsg, me: false})
        this.reqBody.messages.push({role: "assistant", content: botMsg })

        this.showProgress = false
        const spacer = this.$refs.spacer;  
        spacer.style.height = this.passageOffSetHeight; 

        this.save2local()

      } catch (error) {
          this.showBtn = true
          this.showPassage = true
          this.showResponseSection = false
          this.showSwitch = false
          this.switchValue = true
          this.save2local()
          this.showErrorPage=true
          this.showProgress=false
          console.log(error);
      }
    },
    async gen2ndQ(){
      this.showProgress = true
      this.showResponseSection = true
      this.messagesAll=[]
      this.reqBodyRephrase.messages=[]
      this.messageForm.content = ""
 
      this.reqBody.messages.push(  
        {
          role: "user",
          content: "Ask another tough question to check if I understood the passage."
        }
      )
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const botMsg = res.data.choices[0].message.content
        this.messagesAll.push({content: botMsg, me: false})
        this.reqBody.messages.push({role: "assistant", content: botMsg })
        this.showProgress = false
        this.save2local()
      } catch (error) {
          this.showBtn = true
          this.showPassage = true
          this.showResponseSection = false
          this.showSwitch = false
          this.switchValue = true
          this.save2local()
          this.showErrorPage=true
          this.showProgress=false
        console.log(error);
      }
    },
    async whatIsTopic(){
      this.reqBody.messages.push(  
        {
          role: "user",
          content: "In 5 words, what is the story about?"
        }
      )
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const botMsg = res.data.choices[0].message.content
        this.reqBody.messages.push({role: "assistant", content: botMsg })
        this.topic = botMsg
      } catch (error) {
        console.log(error);
      }
      this.gen2ndQ()

    },
    async checkOnAnswer() {
      this.showBtn = false
      this.reqBody.messages.push({role: "user", content: "My answer is '" + this.messageForm.content + "'. If my answer is wrong or incomplete, please don't ask me to try again or ask me any other question."})

      this.showProgress = true
      // Execute when SEND is clicked
      // EH
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        this.numOfQ += 1
        this.messagesAll.push({content: this.messageForm.content, me: true})
        this.showPassage = true
        this.showBtn = true
        const botMsg = res.data.choices[0].message.content
        this.messagesAll.push({content: botMsg, me: false})
        this.reqBody.messages.push({role: "assistant", content: botMsg})
        this.showProgress = false
        this.showResponseSection = false
      } catch (error) {
        this.showErrorPage=true
        this.showReset=true
        this.showProgress=false
        console.log(error);
      }
      this.rightOrWrong()
      // this.isProperSentence()
    },
    async rightOrWrong(){
      this.showProgress = true
      this.reqBody.messages.push({role: "user", content: "with a yes or no, did I get it right?"})
      try {
        const resRightWrong = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const outcome = resRightWrong.data.choices[0].message.content
        this.reqBody.messages.push({role: "assistant", content: outcome})


        if(outcome.toLowerCase().includes("yes")){ // IF GOT RIGHT ANSWER
          this.failedConsecutiveCount = 0
          if(this.switchValue){ // IF EASY
            this.rightAnswersEasy += 1 
            this.totalQuestionsEasy += 1
            this.percentScoreEasy = Math.round(((this.rightAnswersEasy / this.totalQuestionsEasy) * 100) * 10) / 10
            this.saveEasyModeCount2local()
          } else { // IF HARD
            this.rightAnswersHard += 1
            this.totalQuestionsHard += 1
            this.percentScoreHard = Math.round(((this.rightAnswersHard / this.totalQuestionsHard) * 100) * 10) / 10
            this.saveHardModeCount2local()
          }
        } else { // IF GOT WRONG ANSWER
          this.failedConsecutiveCount += 1
          if(this.switchValue){ // IF EASY
            this.totalQuestionsEasy += 1
            this.percentScoreEasy = Math.round(((this.rightAnswersEasy / this.totalQuestionsEasy) * 100) * 10) / 10
            this.saveEasyModeCount2local()
          } else { // IF HARD
            this.totalQuestionsHard += 1
            this.percentScoreHard = Math.round(((this.rightAnswersHard / this.totalQuestionsHard) * 100) * 10) / 10
            this.saveHardModeCount2local()
          }
        }

        this.reportItem = [{level: this.level.ageGrpID, passage: this.passage, messagesAll: this.messagesAll, reqBodyMessages: this.reqBody.messages, storageName: this.storageName}]

        this.showProgress = false
        this.showSwitch = false
        this.showReport = true
        this.save2local()
      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        console.log(error);
      }
      // console.log("Right or Wrong", this.reqBody.messages)
    },
    async isProperSentence() {
      this.showProgress = true
      this.reqBodyRephrase.messages.push(
        {
          role: "system",
          content: "You are an english writing teacher."
        },      
        {
          role: "user",
          content: "Is the sentence, '" + this.messageForm.content + "', a proper full sentence with correct grammar? Answer with a yes or no."
        }
      )
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBodyRephrase),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );

        const outcome = res.data.choices[0].message.content
        this.reqBodyRephrase.messages.push({role: "Assistant", content: outcome})

        if(outcome.toLowerCase().includes("no")){
          this.createTip()
        } else {
          this.showBtn = true
        }
        this.save2local()

      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        console.log(error);
        this.showProgress=false
      }
    },
    async createTip(){
      this.showProgress = true
      this.reqBodyRephrase.messages = [
        {
          role: "system",
          content: "You are an english writing teacher."
        },   
        {
          role: "user",
          content: "Without answering with a yes or no, evaluate the phrase, '" + this.messageForm.content + "', whether it is a proper full sentence with correct grammar"
        }
      ]
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBodyRephrase),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );

        const botMsg = res.data.choices[0].message.content
        this.messagesAll.push({content: "*** WRITING COMMENT *** ", tip: true})
        this.messagesAll.push({content: botMsg, tip: true})
        this.showProgress = false
        this.showResponseSection = false
        this.showBtn = true
        this.save2local()
      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        this.showReset=true
        this.showProgress=false
        console.log(error);
      }
    },
    saveMode2Local(){
      const paramsMode = {
        pStorageName: this.storageName
      }
      localStorage.setItem('selectedMode', JSON.stringify(paramsMode))
    },
    save2local(){
      const params = {
        pSwitchValue: this.switchValue,
        pShowSwitch: this.showSwitch,
        pShowPassage: this.showPassage,
        pShowResponseSection: this.showResponseSection,
        pPassage: this.passage,
        pReqBody: this.reqBody,
        pMessagesAll: this.messagesAll,
        pShowBtn: this.showBtn,
        pNumOfQ: this.numOfQ,
        pFirstTime: this.firstTime,
        pLevel: this.level.ageGrpID,
        pPassageOffsetHeight: this.passageOffSetHeight,
        pFailedConsecutiveCount: this.failedConsecutiveCount,
        pShowReport: this.showReport
      }

      localStorage.setItem(this.storageName, JSON.stringify(params))

      this.saveMode2Local()
    },
    saveEasyModeCount2local(){
      const localStorageName = "EasyModeCount" + this.level.ageGrpID

      const params = {
        pRightAnswersEasy: this.rightAnswersEasy,
        pTotalQEasy: this.totalQuestionsEasy,
        pPercentScoreEasy: this.percentScoreEasy,
      }
      localStorage.setItem(localStorageName, JSON.stringify(params))
    },
    saveHardModeCount2local(){
      const localStorageName = "HardModeCount" + this.level.ageGrpID

      const params = {
        pRightAnswersHard: this.rightAnswersHard,
        pTotalQHard: this.totalQuestionsHard,
        pPercentScoreHard: this.percentScoreHard,
      }
      localStorage.setItem(localStorageName, JSON.stringify(params))
    },
    restoreMode(){
      const paramsUsedMode = JSON.parse(localStorage.getItem('selectedMode'))
      if(paramsUsedMode){
        this.storageName=paramsUsedMode.pStorageName
      }
      this.restoreFromLocal()
    },
    async restoreFromLocal(){
      this.showProgress = true
      const paramsUsed = JSON.parse(localStorage.getItem(this.storageName))

      // if there's something stored locally
      if (paramsUsed){
        this.switchValue = paramsUsed.pSwitchValue
        this.showSwitch = paramsUsed.pShowSwitch
        this.showPassage = paramsUsed.pShowPassage
        this.showResponseSection = paramsUsed.pShowResponseSection
        this.passage = paramsUsed.pPassage
        this.reqBody = paramsUsed.pReqBody
        this.messagesAll = paramsUsed.pMessagesAll
        this.showBtn = paramsUsed.pShowBtn
        this.numOfQ = paramsUsed.pNumOfQ,
        this.firstTime = paramsUsed.pFirstTime,
        this.level.ageGrpID = paramsUsed.pLevel,
        this.passageOffSetHeight = paramsUsed.pPassageOffsetHeight,
        this.failedConsecutiveCount = paramsUsed.pFailedConsecutiveCount,
        this.showReport = paramsUsed.pShowReport
      } 
      this.showProgress = false

      // if(!this.passage){
      //   this.genPassage()
      // }
        this.restoreHardModeCountFromLocal()
        this.restoreEasyModeCountFromLocal()
    },
    async restoreEasyModeCountFromLocal(){
      const localStorageName = "EasyModeCount" + this.level.ageGrpID
      const paramsUsed = JSON.parse(localStorage.getItem(localStorageName))

      if(paramsUsed){
        this.rightAnswersEasy = paramsUsed.pRightAnswersEasy
        this.totalQuestionsEasy = paramsUsed.pTotalQEasy
        this.percentScoreEasy = paramsUsed.pPercentScoreEasy
      }
    },
    async restoreHardModeCountFromLocal(){
      const localStorageName = "HardModeCount" + this.level.ageGrpID
      const paramsUsed = JSON.parse(localStorage.getItem(localStorageName))

      if(paramsUsed){
        this.rightAnswersHard = paramsUsed.pRightAnswersHard
        this.totalQuestionsHard = paramsUsed.pTotalQHard
        this.percentScoreHard = paramsUsed.pPercentScoreHard
      }
    },
    report(){
      this.reportMsg="report"
      this.showConfirm=true
    },
    okay(){
      this.showConfirm = false
      this.showReport = false
    }
  },
  // watch is executed every refresh
  watch: {
    switchValue(){
      if(!this.switchValue){
        this.storageName = "HardMode"
      } else {
        this.storageName =  "EasyMode"
      }

      if(!this.screenRefresh){ // if this is not a screenrefresh
        // reset
        this.rightAnswers = 0
        this.totalQuestions = 0
        this.percentScore = 0
        // this.showSwitch = true

        // retrieve any local storage
        if(this.switchValue){ // Easy Mode
          this.showPassage = true
          this.showSwitch = false
          this.save2local()
          localStorage.removeItem("HardMode")
          this.restoreFromLocal()
        } else if(!this.switchValue){ // Hard Mode
          this.showPassage = false
          this.save2local()
          localStorage.removeItem("EasyMode")
          this.restoreFromLocal()
        }
        this.restoreFromLocal()
        this.screenRefresh = false
      }

      this.screenRefresh = false
      this.save2local()

    },    
    level(){
      this.totalQuestionsEasy = 0
      this.rightAnswersEasy = 0
      this.percentScoreEasy = 0
      this.totalQuestionsHard = 0
      this.rightAnswersHard = 0
      this.percentScoreHard = 0
      this.save2local()
      this.restoreFromLocal()
      this.save2local()
    }, 
  },
};  
</script>  

<style>  
  .hidden {  
    visibility: hidden;  
    height: 0;  
    margin: 0;  
    padding: 0;  
  }  
  .image-with-border {  
    border: 5px solid rgb(54, 146, 7);  
  }  
  .image-with-rounded-corners {  
    border-radius: 20px;  
  }  
</style>  
